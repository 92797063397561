import React from 'react';

import { ContactInfoGrid } from './ContactInfoGrid';
import Paragraph from './Paragraph';

export const ContactInfo = () => (
  <section id="contact" className="relative pt-24 pb-16 lg:pb-24 bg-gray-900">
    <div className="max-w-7xl mx-auto px-4">
      <div className="flex flex-wrap text-center justify-center">
        <div className="w-full lg:w-7/12 px-4">
          <h2 className="text-5xl lg:text-6xl font-display text-blue uppercase tracking-wide">Ota yhteyttä</h2>

          <Paragraph className="mt-4 mb-4 text-gray-400">
            Kysy lisää palveluistamme tai varaa autollesi aika huoltoon. Meidät tavoittaa parhaiten puhelimitse.
            Kiireettömissä asioissa voit lähettää sähköpostia tai täyttää yhteydenottolomakkeen.
          </Paragraph>

          <Paragraph className="my-4 mb-4 text-white font-bold">Olemme suljettuna 23.12.2024 - 1.1.2025.</Paragraph>

          <Paragraph className="mt-4 mb-4 text-white font-bold">
            Vantaan Erikois-Auton väki toivottaa kaikille rauhallista joulua onnellista uuttavuotta!
          </Paragraph>
        </div>

        <ContactInfoGrid />
      </div>
    </div>
  </section>
);
